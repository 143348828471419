.stay-nav-bar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 2;
}

.app_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.App {
    width: 100%;
    max-width: 2000px;
}
.nav_menu {
    display: flex;
}
.nav_mobile {
    display: none;
}
.nav_desktop {
    display: flex;
}
@media only screen and (max-width: 600px) {
    .nav_mobile {
        display: flex;
    }
    .nav_desktop {
        display: none;
    }
    .body {
        padding: 0;
    }
    .logo_item_center {
        font-size: 12px !important;
    }
    .logo_item {
        font-size: 12px !important;
    }
    .logo_bottom {
        font-size: 12px !important;
    }
}
a.active {
    /* border-radius: 5px; */
    background-color: rgba(53, 218, 62, 0.562);
}
.nav {
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    color: white;
    transition: all 0.3s;
    text-decoration: none;
}
.nav:hover {
    text-decoration: none;
    background-color: rgba(53, 218, 62, 0.562);
}
.nav_mobile {
    border-radius: 5px;
    color: blue;
    transition: all 0.3s;
    text-decoration: none;
}
.nav:hover {
    background-color: rgba(53, 218, 62, 0.562);
}
.nav_mobile:hover {
    color: skyblue;
}
.mui_icon {
    font-size: 30px !important;
}
.logo_bottom {
    font-size: 15px;
    font-family: monospace;
}
.logo_item {
    font-size: 15px;
    margin: 5px;
    font-family: sans-serif;
}
.logo_item_center {
    font-size: 17px;
    margin: 5px;
    font-family: sans-serif;
}
.marginBottom {
    margin-bottom: 10px !important;
}

/* IDP */
.input_div {
    position: relative;
}
.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}
.input_div input {
    padding-right: 44px;
}
/* IDP */

.right_left {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 600px) {
    .btn-res {
        flex-direction: column;
        gap: 20px;
    }
    .modal_res {
        width: 85% !important;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.MuiTablePagination-toolbar {
    display: flex;
    align-items: baseline !important;
}
.modal_res {
    overflow: auto;
}

.row .report-date {
    margin-top: calc(3rem - 8px);
    height: 100%;
}
.report-date .MuiInputBase-input {
    padding: 4px 0 5px !important;
    padding: 8px !important;
}
.report-date > div {
    overflow: hidden;
}
/* Address page styling */
.address-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #eaf6f6, #ffffff);
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

.address-card {
    max-width: 600px;
    width: 90%;
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background: #ffffff;
    text-align: left;
}

.address-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
}

.edit-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn:hover {
    background-color: #2980b9;
}

/* Modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: left;
}

.modal-form .form-group {
    margin-bottom: 15px;
}

.modal-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.modal-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn:hover {
    opacity: 0.9;
}

.save-btn {
    background-color: #27ae60;
    color: white;
}

.save-btn:hover {
    background-color: #219150;
}


/* Sahifani to'liq bo'sh joyni egallash */
.address-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; 
    background: linear-gradient(135deg, #eaf6f6, #ffffff);
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    margin: 5vh;
}

/* Address Card uchun style */
.address-card {
    max-width: 600px;
    width: 90%; /* Mobil uchun moslashuv */
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background: #ffffff;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.address-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Title */
.address-title {
    font-size: 18px;
    font-weight: bold;
    /* margin-bottom: 20px; */
    color: #2c3e50;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 2px solid #3498db;
    display: inline-block;
    padding-bottom: 5px;
}

/* Field va Label */
.address-field {
    margin: 10px 0;
    font-size: 18px;
    color: #34495e;
}

.address-label {
    font-weight: bold;
    color: #3498db;
}
