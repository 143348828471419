.company {
    padding: 20px;
}

.addcompany {
    display: flex;
    justify-content: flex-end;
}

.left_right {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .addcompanymodal {
        width: 90% !important;
    }

    .moreInfoModal {
        width: 90% !important;
    }
}

.right_btn {
    margin-top: 20px;
    text-align: right;
}

.moreInfo_wrapper {
    display: flex;
    justify-content: baseline;
}

.moreInfo {
    font-weight: bold;
}

.ca-overlay {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11111;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    cursor: wait;
}
