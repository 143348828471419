* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#hide-file-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

body {
    background-color: whitesmoke;
    padding-top: 70px;
}
