.hotel {
    padding: 20px;
}

.addhotel {
    display: flex;
    justify-content: flex-end;
}

.left_right {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .addhotelmodal {
        width: 90% !important;
    }

    .moreInfoModal {
        width: 90% !important;
    }
}

.right_btn {
    margin-top: 20px;
    text-align: right;
}

.moreInfo_wrapper {
    display: flex;
    justify-content: baseline;
}

.moreInfo {
    font-weight: bold;
}

.ca-overlay {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11111;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    cursor: wait;
}

.hotel-name-on-tab,
.hotel-category-name-on-tab {
    background-color: rgba(238, 130, 238, 0.5);
    display: inline-block;
    font-weight: 600;
    color: #224347;
    padding: 2px 10px;
    border-radius: 2px;
}
@media only screen and (max-width: 500px) {
    .hotel-category-name-on-tab {
        font-size: 12px;
        padding: 2px 5px;
    }
    .hotel-category-tabs .MuiTab-root {
        padding: 0 !important;
    }
}

.MuiList-root > li > div,
.MuiList-root > li > div > button {
    width: 100%;
    height: 100%;
    margin: 0;
}
.MuiList-root > li > div > button {
    float: left;
}

.hotel-tab-menu-mb {
    display: none !important;
}
.hotel-tab-menu-pc-item {
    display: inherit !important;
}
@media only screen and (max-width: 800px) {
    .hotel-tab-menu-mb {
        display: inherit !important;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .hotel-tab-menu-pc-item {
        display: none !important;
    }
}

.hotel-category-tab-menu-mb {
    display: none !important;
}
.hotel-category-tab-menu-pc-item {
    display: inherit !important;
}
@media only screen and (max-width: 600px) {
    .hotel-category-tab-menu-mb {
        display: inherit !important;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .hotel-category-tab-menu-pc-item {
        display: none !important;
    }
}
