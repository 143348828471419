.container,
.container * {
    font-family: sans-serif;
}

.container {
    padding: 20px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.boxContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.box {
    width: calc((100vw - 80px) / 3);
    height: fit-content;
    min-height: 300px;
    background-color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
}

.boxHead {
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgb(0, 153, 255);
    text-transform: uppercase;
    padding: 12px 10px;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.boxBody {
    padding: 12px 10px;
}

.boxKeyValue {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-block: 5px;
}

.boxKey {
    font-weight: 600;
    color: #333;
}

.boxValue {
    color: #333;
}

.boxField {
    font-weight: 500;
    font-size: 1.2rem;
}

.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 140px);
}

.alertMessage {
    padding-inline: 10px;
    max-width: 70rem;
}

.contactContainer {
    width: auto;
}

.settings,
.settings * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.settingsItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
}

.settingsTitle {
    --tw-text-opacity: 0.75;
    color: rgb(79 70 159 / var(--tw-text-opacity));
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.settingsText {
    font-size: 0.9rem;
    color: #555;
}

.line {
    margin-block: 7px 20px;
    color: #555;
}

.line {
    border: none;
    height: 1px;
    background-image: linear-gradient(to right, #c0c0c0, #dfdfdf, #c0c0c0);
}
/* .line::after {
    content: "§";
    font-size: small;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(60deg);
    transform-origin: 50% 50%;
    padding: 1rem;
    /* background-color: ; 
} */
