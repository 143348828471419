.category-image-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.category-image-box {
    width: var(--width);
    height: calc(1.2 * var(--width));
    border: 1px solid #aaa;
    border-radius: 5px;
    transition: 0.2s;
    position: relative;
}

.deleting-index {
    display: none;
}

.image-deleting > .category-image-box {
    padding: 5px;
    cursor: pointer;
}

.image-deleting .deleting-index {
    display: inline-block;
    cursor: inherit;
    text-align: center;
    line-height: 1.1;
    font-weight: 800;
    color: white;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    margin: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.deleting-index.check {
    background-color: cornflowerblue;
}

.deleting-index.not-check {
    border: 2px solid cornflowerblue;
    background-color: transparent;
}

.category-image-box > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.showing {
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    opacity: 0;
    cursor: pointer;
    background-color: black;

    color: white;
    font-size: 1.5em;
    transition: 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;
}

.category-image-box:hover .showing {
    opacity: 0.4;
}

.modal-image {
    width: 100%;
    /* height: fit-content; */
}

.upload-image {
    border: 1px dashed black;
    border-radius: 0;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    padding-bottom: 15px;
}

.upload-image:hover {
    border-color: cornflowerblue;
    cursor: pointer;
    color: cornflowerblue;
}

.upload-image > span:first-child {
    font-size: 2rem;
}
