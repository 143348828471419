.tableItem,
.itemHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 100%;
    padding: 4px;
}

.itemHead {
    gap: 10px;
}

.itemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.itemName {
    font-weight: 500;
    /* text-transform: capitalize; */
    /* font-size: 16px; */
}

.itemEmail {
    /* font-size: 13.5px; */
    color: #8a8264;
}

.itemStatus {
    width: 7px;
    height: 7px;
    border-radius: 2px;
    transform: rotate(45deg);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.itemDetails {
    margin-right: 20px;
}
