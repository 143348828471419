.shopping-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 100px; /* Leave space for the cart summary */
  }
  
  .product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
  }
  
  .product-item img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .quantity-controls button {
    background-color: #6200EE;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .quantity-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .quantity-controls span {
    font-size: 16px;
  }
  
  .cart-summary {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #6200EE;
    color: #FFFFFF;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
  }
  